// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries images => https://github.com/djaiss/mapsicon

export const countryCodesWithImage = [
       'municip5020',
       'municip5022',
       'municip5021',
       'municip5028',
       'municip5027',
       'municip5029',
       'municip4602',
       'municip4601',
       'municip3338',
       'municip4612',
       'municip3336',
       'municip4611',
       'municip3234',
       'municip5026',
       'municip5025',
       'municip4614',
       'municip3236',
       'municip4613',
       'municip3314',
       'municip3316',
       'municip3334',
       'municip5006',
       'municip3312',
       'municip5001',
       'municip5014',
       'municip3238',
       'municip3240',
       'municip5007',
       'municip3310',
       'municip3242',
       'municip3230',
       'municip3232',
       'municip3228',
       'municip3209',
       'municip3330',
       'municip3328',
       'municip3318',
       'municip3332',
       'municip3322',
       'municip3320',
       'municip3326',
       'municip3324',
       'municip3207',
       'municip3216',
       'municip3214',
       'municip3218',
       'municip3120',
       'municip3116',
       'municip3114',
       'municip1160',
       'municip3112',
       'municip3220',
       'municip3224',
       'municip3205',
       'municip3222',
       'municip3201',
       'municip3212',
       'municip3226',
       'municip3203',
       'municip4651',
       'municip4648',
       'municip4647',
       'municip4650',
       'municip4649',
       'municip4636',
       'municip4635',
       'municip4638',
       'municip4637',
       'municip4632',
       'municip4631',
       'municip4634',
       'municip4633',
       'municip4644',
       'municip4643',
       'municip4646',
       'municip4645',
       'municip4640',
       'municip4639',
       'municip4642',
       'municip4641',
       'municip4620',
       'municip4619',
       'municip4622',
       'municip4621',
       'municip4616',
       'municip4615',
       'municip4618',
       'municip4617',
       'municip4628',
       'municip4627',
       'municip4630',
       'municip4629',
       'municip0301',
       'municip4624',
       'municip4623',
       'municip4626',
       'municip4625',
       'municip4220',
       'municip4219',
       'municip4222',
       'municip4221',
       'municip4216',
       'municip4215',
       'municip4218',
       'municip4217',
       'municip4228',
       'municip4227',
       'municip4224',
       'municip4223',
       'municip4226',
       'municip4225',
       'municip4204',
       'municip4203',
       'municip4206',
       'municip4205',
       'municip4202',
       'municip4201',
       'municip4212',
       'municip4211',
       'municip4214',
       'municip4213',
       'municip4207',
       'municip4022',
       'municip4024',
       'municip4030',
       'municip4028',
       'municip4018',
       'municip4016',
       'municip4026',
       'municip4020',
       'municip4034',
       'municip4032',
       'municip1851',
       'municip4036',
       'municip1853',
       'municip3907',
       'municip1848',
       'municip3905',
       'municip4001',
       'municip3909',
       'municip1860',
       'municip1859',
       'municip3903',
       'municip3901',
       'municip4010',
       'municip1856',
       'municip3911',
       'municip4014',
       'municip4012',
       'municip1857',
       'municip1836',
       'municip4005',
       'municip1835',
       'municip4003',
       'municip1838',
       'municip1837',
       'municip1832',
       'municip1834',
       'municip1833',
       'municip1845',
       'municip1840',
       'municip1839',
       'municip1532',
       'municip1841',
       'municip1531',
       'municip1820',
       'municip1822',
       'municip1528',
       'municip1816',
       'municip1815',
       'municip1818',
       'municip1539',
       'municip1828',
       'municip1827',
       'municip1535',
       'municip1824',
       'municip5622',
       'municip1516',
       'municip5620',
       'municip1826',
       'municip1825',
       'municip1515',
       'municip5624',
       'municip1804',
       'municip5610',
       'municip1517',
       'municip5614',
       'municip1806',
       'municip1511',
       'municip5618',
       'municip5616',
       'municip1514',
       'municip5605',
       'municip5632',
       'municip1812',
       'municip5630',
       'municip1811',
       'municip1525',
       'municip5626',
       'municip1520',
       'municip5636',
       'municip1813',
       'municip5628',
       'municip5528',
       'municip5526',
       'municip5532',
       'municip5530',
       'municip5520',
       'municip5518',
       'municip5524',
       'municip5522',
       'municip5544',
       'municip5542',
       'municip5612',
       'municip1508',
       'municip5546',
       'municip5536',
       'municip5534',
       'municip5540',
       'municip5538',
       'municip1506',
       'municip5634',
       'municip1505',
       'municip5601',
       'municip5603',
       'municip5607',
       'municip3452',
       'municip5503',
       'municip3451',
       'municip5501',
       'municip3454',
       'municip3453',
       'municip5512',
       'municip5510',
       'municip3448',
       'municip5516',
       'municip3447',
       'municip5514',
       'municip3450',
       'municip3449',
       'municip3436',
       'municip3435',
       'municip3438',
       'municip3437',
       'municip3432',
       'municip3431',
       'municip3434',
       'municip3433',
       'municip3443',
       'municip3446',
       'municip3440',
       'municip3439',
       'municip1579',
       'municip3442',
       'municip3441',
       'municip3420',
       'municip1576',
       'municip3419',
       'municip3422',
       'municip1578',
       'municip3421',
       'municip1577',
       'municip3416',
       'municip3415',
       'municip3418',
       'municip3417',
       'municip1868',
       'municip3428',
       'municip1867',
       'municip3427',
       'municip1870',
       'municip3430',
       'municip3429',
       'municip3424',
       'municip1563',
       'municip3423',
       'municip1866',
       'municip1865',
       'municip1566',
       'municip3426',
       'municip3425',
       'municip1875',
       'municip1560',
       'municip3403',
       'municip3405',
       'municip1871',
       'municip1874',
       'municip3401',
       'municip1573',
       'municip3412',
       'municip3411',
       'municip3414',
       'municip3413',
       'municip3407',
       'municip1547',
       'municip1557',
       'municip3107',
       'municip1554',
       'municip3105',
       'municip3303',
       'municip3301',
       'municip1149',
       'municip3103',
       'municip1144',
       'municip3101',
       'municip3124',
       'municip1146',
       'municip3110',
       'municip1145',
       'municip3118',
       'municip3122',
       'municip3305',
       'municip1151',
       'municip1134',
       'municip1133',
       'municip1127',
       'municip1130',
       'municip1135',
       'municip5052',
       'municip5054',
       'municip1112',
       'municip5053',
       'municip1111',
       'municip1114',
       'municip5047',
       'municip1124',
       'municip5049',
       'municip5060',
       'municip5059',
       'municip1120',
       'municip5061',
       'municip5056',
       'municip1119',
       'municip1122',
       'municip5055',
       'municip1121',
       'municip5058',
       'municip5057',
       'municip5036',
       'municip5035',
       'municip1101',
       'municip5038',
       'municip5037',
       'municip5032',
       'municip5031',
       'municip5034',
       'municip5033',
       'municip1108',
       'municip5044',
       'municip5043',
       'municip5046',
       'municip5045',
       'municip1103',
       'municip1106',
       'municip5042',
       'municip5041',
       'municip1580',
];

export interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}

export const countries = [
{
code: "municip5020",
longitude: 10.6609468284909,
latitude: 64.2694574828099,
name: "Osen"
},  

{
code: "municip5022",
longitude: 9.87619464455965,
latitude: 62.8095286432537,
name: "Rennebu"
},  

{
code: "municip5021",
longitude: 9.54050337141872,
latitude: 62.5403916306017,
name: "Oppdal"
},  

{
code: "municip5028",
longitude: 10.2693399030651,
latitude: 63.1590659685781,
name: "Melhus"
},  

{
code: "municip5027",
longitude: 10.5306760654423,
latitude: 62.9020603431414,
name: "Midtre Gauldal"
},  

{
code: "municip5029",
longitude: 10.0337383980485,
latitude: 63.2620279635456,
name: "Skaun"
},  

{
code: "municip4602",
longitude: 5.41156572933664,
latitude: 61.624314051777,
name: "Kinn"
},  

{
code: "municip4601",
longitude: 5.39046214644439,
latitude: 60.3618994341146,
name: "Bergen"
},  

{
code: "municip3338",
longitude: 8.45067450368184,
latitude: 60.2754413931999,
name: "Nore og Uvdal"
},  

{
code: "municip4612",
longitude: 5.40333618773439,
latitude: 59.5787295122596,
name: "Sveio"
},  

{
code: "municip3336",
longitude: 9.21676335780663,
latitude: 60.0102916046675,
name: "Rollag"
},  

{
code: "municip4611",
longitude: 6.19338069023621,
latitude: 59.7756657114134,
name: "Etne"
},  

{
code: "municip3234",
longitude: 10.6664042447915,
latitude: 60.2438387509904,
name: "Lunner"
},  

{
code: "municip5026",
longitude: 11.2475351699255,
latitude: 62.8513245980631,
name: "Holtålen"
},  

{
code: "municip5025",
longitude: 11.7298939390966,
latitude: 62.5762532037738,
name: "Røros"
},  

{
code: "municip4614",
longitude: 5.4583913388046,
latitude: 59.8214219218602,
name: "Stord"
},  

{
code: "municip3236",
longitude: 10.3932075182027,
latitude: 60.2791910496837,
name: "Jevnaker"
},  

{
code: "municip4613",
longitude: 5.21759186730056,
latitude: 59.735603827615,
name: "Bømlo"
},  

{
code: "municip3314",
longitude: 9.84977716784363,
latitude: 59.7558490743485,
name: "Øvre Eiker"
},  

{
code: "municip3316",
longitude: 9.95890138896907,
latitude: 59.9848733672863,
name: "Modum"
},  

{
code: "municip3334",
longitude: 9.49344127849097,
latitude: 59.8473975645879,
name: "Flesberg"
},  

{
code: "municip5006",
longitude: 11.5280517496921,
latitude: 64.0483150456377,
name: "Steinkjer"
},  

{
code: "municip3312",
longitude: 10.232031961851,
latitude: 59.8633826436745,
name: "Lier"
},  

{
code: "municip5001",
longitude: 10.432692876495,
latitude: 63.341906917024,
name: "Trondheim"
},  

{
code: "municip5014",
longitude: 8.64411830614487,
latitude: 63.7123550958322,
name: "Frøya"
},  

{
code: "municip3238",
longitude: 10.9637537920975,
latitude: 60.235497303446,
name: "Nannestad"
},  

{
code: "municip3240",
longitude: 11.2278194615069,
latitude: 60.3919135500541,
name: "Eidsvoll"
},  

{
code: "municip5007",
longitude: 11.2482334662076,
latitude: 64.297448579882,
name: "Namsos"
},  

{
code: "municip3310",
longitude: 10.2936123207331,
latitude: 60.0422090074607,
name: "Hole"
},  

{
code: "municip3242",
longitude: 10.9525660233945,
latitude: 60.4285350145669,
name: "Hurdal"
},  

{
code: "municip3230",
longitude: 11.0075680959299,
latitude: 60.0822472182541,
name: "Gjerdrum"
},  

{
code: "municip3232",
longitude: 10.8538973373656,
latitude: 60.0846966071322,
name: "Nittedal"
},  

{
code: "municip3228",
longitude: 11.4999769936935,
latitude: 60.1387912186057,
name: "Nes"
},  

{
code: "municip3209",
longitude: 11.1983888305795,
latitude: 60.150143972776,
name: "Ullensaker"
},  

{
code: "municip3330",
longitude: 8.01101220958459,
latitude: 60.5706751425148,
name: "Hol"
},  

{
code: "municip3328",
longitude: 8.39093545048476,
latitude: 60.7096504743211,
name: "Ål"
},  

{
code: "municip3318",
longitude: 9.66669397642944,
latitude: 60.198043058383,
name: "Krødsherad"
},  

{
code: "municip3332",
longitude: 9.45722920912406,
latitude: 60.1367231371742,
name: "Sigdal"
},  

{
code: "municip3322",
longitude: 9.06897810778099,
latitude: 60.550692307668,
name: "Nesbyen"
},  

{
code: "municip3320",
longitude: 9.5015579899796,
latitude: 60.4173072449032,
name: "Flå"
},  

{
code: "municip3326",
longitude: 8.47058402276918,
latitude: 60.9110606232704,
name: "Hemsedal"
},  

{
code: "municip3324",
longitude: 8.99935795673934,
latitude: 60.7435201494128,
name: "Gol"
},  

{
code: "municip3207",
longitude: 10.8828487897654,
latitude: 59.7398046461242,
name: "Nordre Follo"
},  

{
code: "municip3216",
longitude: 10.7361898856169,
latitude: 59.5668282428419,
name: "Vestby"
},  

{
code: "municip3214",
longitude: 10.6656629855187,
latitude: 59.7066262470547,
name: "Frogn"
},  

{
code: "municip3218",
longitude: 10.7798379563839,
latitude: 59.6784619928992,
name: "Ås"
},  

{
code: "municip3120",
longitude: 11.4167093287894,
latitude: 59.370243810939,
name: "Rakkestad"
},  

{
code: "municip3116",
longitude: 11.1527169558822,
latitude: 59.478963298228,
name: "Skiptvet"
},  

{
code: "municip3114",
longitude: 10.9337203804411,
latitude: 59.4614034180622,
name: "Våler (Østfold)"
},  

{
code: "municip1160",
longitude: 5.80034202110943,
latitude: 59.5484156556497,
name: "Vindafjord"
},  

{
code: "municip3112",
longitude: 10.8637304682709,
latitude: 59.3484403188251,
name: "Råde"
},  

{
code: "municip3220",
longitude: 11.1050344447031,
latitude: 59.7642470283348,
name: "Enebakk"
},  

{
code: "municip3224",
longitude: 11.0812903604513,
latitude: 59.8816957914848,
name: "Rælingen"
},  

{
code: "municip3205",
longitude: 11.2031095666398,
latitude: 59.9486456110622,
name: "Lillestrøm"
},  

{
code: "municip3222",
longitude: 10.9752669574001,
latitude: 59.8908371871826,
name: "Lørenskog"
},  

{
code: "municip3201",
longitude: 10.4927610407993,
latitude: 59.9430566450618,
name: "Bærum"
},  

{
code: "municip3212",
longitude: 10.6425631643995,
latitude: 59.7882661054456,
name: "Nesodden"
},  

{
code: "municip3226",
longitude: 11.613606322571,
latitude: 59.8188140674562,
name: "Aurskog-Høland"
},  

{
code: "municip3203",
longitude: 10.460359634593,
latitude: 59.6991007640846,
name: "Asker"
},  

{
code: "municip4651",
longitude: 6.91599729223521,
latitude: 61.8476051418056,
name: "Stryn"
},  

{
code: "municip4648",
longitude: 5.43730549033079,
latitude: 61.7911112405927,
name: "Bremanger"
},  

{
code: "municip4647",
longitude: 6.11759439681808,
latitude: 61.4718879800586,
name: "Sunnfjord"
},  

{
code: "municip4650",
longitude: 6.16033010659971,
latitude: 61.7192892335952,
name: "Gloppen"
},  

{
code: "municip4649",
longitude: 5.76920656476133,
latitude: 61.9742092734291,
name: "Stad"
},  

{
code: "municip4636",
longitude: 4.91732284442957,
latitude: 61.1310130105293,
name: "Solund"
},  

{
code: "municip4635",
longitude: 5.28258008739195,
latitude: 60.9802448521957,
name: "Gulen"
},  

{
code: "municip4638",
longitude: 5.96344049681852,
latitude: 61.2040350678507,
name: "Høyanger"
},  

{
code: "municip4637",
longitude: 5.28091664179754,
latitude: 61.1863541565369,
name: "Hyllestad"
},  

{
code: "municip4632",
longitude: 4.92305786952428,
latitude: 60.7688271734606,
name: "Austrheim"
},  

{
code: "municip4631",
longitude: 5.31685685799793,
latitude: 60.6878353783229,
name: "Alver"
},  

{
code: "municip4634",
longitude: 5.49804786348045,
latitude: 60.8665739135894,
name: "Masfjorden"
},  

{
code: "municip4633",
longitude: 4.72440387429029,
latitude: 60.7706570127387,
name: "Fedje"
},  

{
code: "municip4644",
longitude: 7.44746523811775,
latitude: 61.5416251049697,
name: "Luster"
},  

{
code: "municip4643",
longitude: 7.81778082698453,
latitude: 61.2941053457873,
name: "Årdal"
},  

{
code: "municip4646",
longitude: 5.42198559071194,
latitude: 61.2822547357387,
name: "Fjaler"
},  

{
code: "municip4645",
longitude: 5.23674558041574,
latitude: 61.4111512821782,
name: "Askvoll"
},  

{
code: "municip4640",
longitude: 6.79585586923717,
latitude: 61.2913991575766,
name: "Sogndal"
},  

{
code: "municip4639",
longitude: 6.54325877931186,
latitude: 61.0210732316854,
name: "Vik"
},  

{
code: "municip4642",
longitude: 7.74229571667165,
latitude: 61.0417633006959,
name: "Lærdal"
},  

{
code: "municip4641",
longitude: 7.24423653758367,
latitude: 60.8573874098853,
name: "Aurland"
},  

{
code: "municip4620",
longitude: 7.1911917235431,
latitude: 60.6102098569632,
name: "Ulvik"
},  

{
code: "municip4619",
longitude: 7.3613640118035,
latitude: 60.3673983790725,
name: "Eidfjord"
},  

{
code: "municip4622",
longitude: 6.11809251240198,
latitude: 60.3863448461834,
name: "Kvam"
},  

{
code: "municip4621",
longitude: 6.46730540002037,
latitude: 60.6867261899018,
name: "Voss herad"
},  

{
code: "municip4616",
longitude: 5.57348746333235,
latitude: 59.9907416893488,
name: "Tysnes"
},  

{
code: "municip4615",
longitude: 5.36843808902098,
latitude: 59.9032635899927,
name: "Fitjar"
},  

{
code: "municip4618",
longitude: 6.81412161199008,
latitude: 60.0964033753935,
name: "Ullensvang"
},  

{
code: "municip4617",
longitude: 6.11916944042317,
latitude: 59.9738542458885,
name: "Kvinnherad"
},  

{
code: "municip4628",
longitude: 5.94247365888054,
latitude: 60.6833710658031,
name: "Vaksdal"
},  

{
code: "municip4627",
longitude: 5.14957534164754,
latitude: 60.4680111257131,
name: "Askøy"
},  

{
code: "municip4630",
longitude: 5.5503632137573,
latitude: 60.5320033904871,
name: "Osterøy"
},  

{
code: "municip4629",
longitude: 5.93552498880746,
latitude: 60.8887996713285,
name: "Modalen"
},  

{
code: "municip0301",
longitude: 10.7447370024655,
latitude: 59.9788230435071,
name: "Oslo-Oslove"
},  

{
code: "municip4624",
longitude: 5.776955522911,
latitude: 60.2149333943367,
name: "Bjørnafjorden"
},  

{
code: "municip4623",
longitude: 5.79391386006463,
latitude: 60.400483755621,
name: "Samnanger"
},  

{
code: "municip4626",
longitude: 5.04856913151022,
latitude: 60.3025693465612,
name: "Øygarden"
},  

{
code: "municip4625",
longitude: 5.24311485571077,
latitude: 60.0496147119574,
name: "Austevoll"
},  

{
code: "municip4220",
longitude: 7.65374565187681,
latitude: 58.8871019986732,
name: "Bygland"
},  

{
code: "municip4219",
longitude: 7.76246076610397,
latitude: 58.580750541915,
name: "Evje og Hornnes"
},  

{
code: "municip4222",
longitude: 7.20232035392756,
latitude: 59.4393827337145,
name: "Bykle"
},  

{
code: "municip4221",
longitude: 7.43309915820512,
latitude: 59.1662620580752,
name: "Valle"
},  

{
code: "municip4216",
longitude: 8.19792725438927,
latitude: 58.4466499932854,
name: "Birkenes"
},  

{
code: "municip4215",
longitude: 8.2870373710573,
latitude: 58.2354693530283,
name: "Lillesand"
},  

{
code: "municip4218",
longitude: 7.94610204469458,
latitude: 58.4362454151471,
name: "Iveland"
},  

{
code: "municip4217",
longitude: 8.33738454292684,
latitude: 58.8033314849721,
name: "Åmli"
},  

{
code: "municip4228",
longitude: 6.83872500671537,
latitude: 58.8660319362075,
name: "Sirdal"
},  

{
code: "municip4227",
longitude: 6.97712010744075,
latitude: 58.5084528918315,
name: "Kvinesdal"
},  

{
code: "municip4224",
longitude: 7.40264056752782,
latitude: 58.6995111672423,
name: "Åseral"
},  

{
code: "municip4223",
longitude: 7.83006176649273,
latitude: 58.329661345301,
name: "Vennesla"
},  

{
code: "municip4226",
longitude: 7.20232797815029,
latitude: 58.4747854955671,
name: "Hægebostad"
},  

{
code: "municip4225",
longitude: 7.20654586950392,
latitude: 58.2572274232581,
name: "Lyngdal"
},  

{
code: "municip4204",
longitude: 7.84905463960533,
latitude: 58.1890377590782,
name: "Kristiansand"
},  

{
code: "municip4203",
longitude: 8.74902221372726,
latitude: 58.4956539461056,
name: "Arendal"
},  

{
code: "municip4206",
longitude: 6.73513224386171,
latitude: 58.1449109198068,
name: "Farsund"
},  

{
code: "municip4205",
longitude: 7.45174301803887,
latitude: 58.1846099987343,
name: "Lindesnes"
},  

{
code: "municip4202",
longitude: 8.48517711314384,
latitude: 58.3783714430471,
name: "Grimstad"
},  

{
code: "municip4201",
longitude: 9.15281732588851,
latitude: 58.7543012253155,
name: "Risør"
},  

{
code: "municip4212",
longitude: 8.82499923432892,
latitude: 58.7606424506427,
name: "Vegårshei"
},  

{
code: "municip4211",
longitude: 8.97738275447747,
latitude: 58.8911121176368,
name: "Gjerstad"
},  

{
code: "municip4214",
longitude: 8.41940491894671,
latitude: 58.5971463363002,
name: "Froland"
},  

{
code: "municip4213",
longitude: 8.93027143901728,
latitude: 58.6388519310555,
name: "Tvedestrand"
},  

{
code: "municip4207",
longitude: 6.69754867767525,
latitude: 58.3838018463347,
name: "Flekkefjord"
},  

{
code: "municip4022",
longitude: 8.53483349977511,
latitude: 59.5663243144972,
name: "Seljord"
},  

{
code: "municip4024",
longitude: 8.70582104549823,
latitude: 59.7024397368292,
name: "Hjartdal"
},  

{
code: "municip4030",
longitude: 8.54099949904481,
latitude: 59.0571355003815,
name: "Nissedal"
},  

{
code: "municip4028",
longitude: 8.49541975577778,
latitude: 59.370071811591,
name: "Kviteseid"
},  

{
code: "municip4018",
longitude: 9.12458873156802,
latitude: 59.2741220372794,
name: "Nome"
},  

{
code: "municip4016",
longitude: 8.97575428142708,
latitude: 59.1022302762454,
name: "Drangedal"
},  

{
code: "municip4026",
longitude: 8.55615097034344,
latitude: 59.9972699877952,
name: "Tinn"
},  

{
code: "municip4020",
longitude: 9.13165959519967,
latitude: 59.4295779013466,
name: "Midt-Telemark"
},  

{
code: "municip4034",
longitude: 7.91320171665563,
latitude: 59.4608879917294,
name: "Tokke"
},  

{
code: "municip4032",
longitude: 8.0571345937644,
latitude: 59.1590210312688,
name: "Fyresdal"
},  

{
code: "municip1851",
longitude: 15.6947693634698,
latitude: 68.4486712399332,
name: "Lødingen"
},  

{
code: "municip4036",
longitude: 7.72887253900917,
latitude: 59.820291269332,
name: "Vinje"
},  

{
code: "municip1853",
longitude: 16.9719522326882,
latitude: 68.5130718440602,
name: "Evenes"
},  

{
code: "municip3907",
longitude: 10.1763895207498,
latitude: 59.2412171053527,
name: "Sandefjord"
},  

{
code: "municip1848",
longitude: 15.289495178716,
latitude: 67.822309513622,
name: "Steigen"
},  

{
code: "municip3905",
longitude: 10.289964448231,
latitude: 59.3650861432027,
name: "Tønsberg"
},  

{
code: "municip4001",
longitude: 9.75718745603314,
latitude: 59.1219702038328,
name: "Porsgrunn"
},  

{
code: "municip3909",
longitude: 9.95572811210191,
latitude: 59.2100402232215,
name: "Larvik"
},  

{
code: "municip1860",
longitude: 13.781476036889,
latitude: 68.213408520051,
name: "Vestvågøy"
},  

{
code: "municip1859",
longitude: 13.3170299487286,
latitude: 68.079160053364,
name: "Flakstad"
},  

{
code: "municip3903",
longitude: 10.1595712338763,
latitude: 59.5651821538624,
name: "Holmestrand"
},  

{
code: "municip3901",
longitude: 10.4237754561653,
latitude: 59.40110891564,
name: "Horten"
},  

{
code: "municip4010",
longitude: 9.72579515303856,
latitude: 59.3072013450903,
name: "Siljan"
},  

{
code: "municip1856",
longitude: 12.0962607922683,
latitude: 67.5198132614023,
name: "Røst"
},  

{
code: "municip3911",
longitude: 10.4080466682844,
latitude: 59.2092807894597,
name: "Færder"
},  

{
code: "municip4014",
longitude: 9.31125680197756,
latitude: 58.8823704931999,
name: "Kragerø"
},  

{
code: "municip4012",
longitude: 9.52838625388817,
latitude: 59.0128893741745,
name: "Bamble"
},  

{
code: "municip1857",
longitude: 12.6526196357705,
latitude: 67.6639069994644,
name: "Værøy"
},  

{
code: "municip1836",
longitude: 13.4860989478293,
latitude: 66.563290035664,
name: "Rødøy"
},  

{
code: "municip4005",
longitude: 9.15396078423973,
latitude: 59.6542764638784,
name: "Notodden"
},  

{
code: "municip1835",
longitude: 12.0268300679862,
latitude: 66.5040941189239,
name: "Træna"
},  

{
code: "municip4003",
longitude: 9.48491611418633,
latitude: 59.255160717398,
name: "Skien"
},  

{
code: "municip1838",
longitude: 14.124779978949,
latitude: 66.92655589987,
name: "Gildeskål"
},  

{
code: "municip1837",
longitude: 13.9145381668297,
latitude: 66.7597744626054,
name: "Meløy"
},  

{
code: "municip1832",
longitude: 14.0660926670092,
latitude: 65.9999828113719,
name: "Hemnes"
},  

{
code: "municip1834",
longitude: 13.135375351372,
latitude: 66.3979408744935,
name: "Lurøy"
},  

{
code: "municip1833",
longitude: 14.5077822949119,
latitude: 66.414413988528,
name: "Rana"
},  

{
code: "municip1845",
longitude: 15.7978188146419,
latitude: 67.5118199554318,
name: "Sørfold"
},  

{
code: "municip1840",
longitude: 15.5240571301585,
latitude: 66.8848654577049,
name: "Saltdal"
},  

{
code: "municip1839",
longitude: 14.6569455300546,
latitude: 66.8859323031592,
name: "Beiarn"
},  

{
code: "municip1532",
longitude: 6.08459709606515,
latitude: 62.5603505935762,
name: "Giske"
},  

{
code: "municip1841",
longitude: 15.8067259092951,
latitude: 67.2201347712905,
name: "Fauske-Fuosko"
},  

{
code: "municip1531",
longitude: 6.19911450325223,
latitude: 62.4222417066367,
name: "Sula"
},  

{
code: "municip1820",
longitude: 12.5550553369368,
latitude: 65.9471631918439,
name: "Alstahaug"
},  

{
code: "municip1822",
longitude: 13.0710053242699,
latitude: 66.0932468114812,
name: "Leirfjord"
},  

{
code: "municip1528",
longitude: 6.65783388810968,
latitude: 62.3473329117024,
name: "Sykkylven"
},  

{
code: "municip1816",
longitude: 12.7163923468646,
latitude: 65.6372594640579,
name: "Vevelstad"
},  

{
code: "municip1815",
longitude: 11.8963599070379,
latitude: 65.6553589830953,
name: "Vega"
},  

{
code: "municip1818",
longitude: 12.3171484111105,
latitude: 65.9972701719428,
name: "Herøy (Nordland)"
},  

{
code: "municip1539",
longitude: 7.75659276067635,
latitude: 62.4523614671463,
name: "Rauma"
},  

{
code: "municip1828",
longitude: 13.1747596356723,
latitude: 66.234365917998,
name: "Nesna"
},  

{
code: "municip1827",
longitude: 12.5062210591515,
latitude: 66.1055380903235,
name: "Dønna"
},  

{
code: "municip1535",
longitude: 7.03532345840673,
latitude: 62.5611139887335,
name: "Vestnes"
},  

{
code: "municip1824",
longitude: 13.227778087883,
latitude: 65.8517398223304,
name: "Vefsn"
},  

{
code: "municip5622",
longitude: 25.1185744547305,
latitude: 70.1464531202874,
name: "Porsanger-Porsáŋgu-Porsanki"
},  

{
code: "municip1516",
longitude: 5.88427768416054,
latitude: 62.3360098583164,
name: "Ulstein"
},  

{
code: "municip5620",
longitude: 25.7033801005796,
latitude: 71.0504222350288,
name: "Nordkapp"
},  

{
code: "municip1826",
longitude: 14.1364234876421,
latitude: 65.5054259388124,
name: "Hattfjelldal"
},  

{
code: "municip1825",
longitude: 13.4483625631889,
latitude: 65.4000165530363,
name: "Grane"
},  

{
code: "municip1515",
longitude: 5.718691304655,
latitude: 62.2666073449117,
name: "Herøy (Møre og Romsdal)"
},  

{
code: "municip5624",
longitude: 26.7503024717865,
latitude: 70.4752738979735,
name: "Lebesby"
},  

{
code: "municip1804",
longitude: 14.9108124021159,
latitude: 67.1260765564187,
name: "Bodø"
},  

{
code: "municip5610",
longitude: 25.1376042498403,
latitude: 69.4080108845979,
name: "Kárášjohka-Karasjok"
},  

{
code: "municip1517",
longitude: 5.98316255601207,
latitude: 62.3521406020893,
name: "Hareid"
},  

{
code: "municip5614",
longitude: 21.9700405755121,
latitude: 70.1996943652579,
name: "Loppa"
},  

{
code: "municip1806",
longitude: 17.3403914126246,
latitude: 68.2716453672416,
name: "Narvik"
},  

{
code: "municip1511",
longitude: 5.64645083208375,
latitude: 62.0854209139949,
name: "Vanylven"
},  

{
code: "municip5618",
longitude: 24.8652392176942,
latitude: 70.8005545204759,
name: "Måsøy"
},  

{
code: "municip5616",
longitude: 22.4587245289693,
latitude: 70.6045899871967,
name: "Hasvik"
},  

{
code: "municip1514",
longitude: 5.63080780410663,
latitude: 62.2307637989138,
name: "Sande"
},  

{
code: "municip5605",
longitude: 29.6114752292147,
latitude: 69.6193686349883,
name: "Sør-Varanger"
},  

{
code: "municip5632",
longitude: 29.9427739416308,
latitude: 70.4976287627054,
name: "Båtsfjord"
},  

{
code: "municip1812",
longitude: 12.2473366151025,
latitude: 65.3340561750746,
name: "Sømna"
},  

{
code: "municip5630",
longitude: 29.0440988073225,
latitude: 70.6792985127914,
name: "Berlevåg"
},  

{
code: "municip1811",
longitude: 12.7149278584492,
latitude: 65.0833408664806,
name: "Bindal"
},  

{
code: "municip1525",
longitude: 6.97629536075606,
latitude: 62.124328545968,
name: "Stranda"
},  

{
code: "municip5626",
longitude: 27.8963697405378,
latitude: 70.7923506617659,
name: "Gamvik"
},  

{
code: "municip1520",
longitude: 6.27332183184388,
latitude: 62.2266274776691,
name: "Ørsta"
},  

{
code: "municip5636",
longitude: 28.7765846965454,
latitude: 70.1379056966008,
name: "Unjárga-Nesseby"
},  

{
code: "municip1813",
longitude: 12.706635465996,
latitude: 65.4046932602891,
name: "Brønnøy"
},  

{
code: "municip5628",
longitude: 27.6237185269691,
latitude: 70.2141592344284,
name: "Deatnu-Tana"
},  

{
code: "municip5528",
longitude: 17.7473546982811,
latitude: 69.0320089417176,
name: "Dyrøy"
},  

{
code: "municip5526",
longitude: 18.1732817126674,
latitude: 69.0931263072631,
name: "Sørreisa"
},  

{
code: "municip5532",
longitude: 19.2594924867061,
latitude: 69.2451962830562,
name: "Balsfjord"
},  

{
code: "municip5530",
longitude: 17.4726311258967,
latitude: 69.3083916988324,
name: "Senja"
},  

{
code: "municip5520",
longitude: 18.8969685953636,
latitude: 68.6640372071565,
name: "Bardu"
},  

{
code: "municip5518",
longitude: 17.8100036587249,
latitude: 68.7288539353592,
name: "Loabák-Lavangen"
},  

{
code: "municip5524",
longitude: 19.3868982626138,
latitude: 68.9121232781192,
name: "Målselv"
},  

{
code: "municip5522",
longitude: 17.882695184863,
latitude: 68.89197407684,
name: "Salangen"
},  

{
code: "municip5544",
longitude: 21.5894835896796,
latitude: 69.509573151202,
name: "Nordreisa"
},  

{
code: "municip5542",
longitude: 20.5960835059086,
latitude: 70.1341100377981,
name: "Skjervøy"
},  

{
code: "municip5612",
longitude: 23.5552375044807,
latitude: 69.1316575169366,
name: "Guovdageaidnu-Kautokeino"
},  

{
code: "municip1508",
longitude: 6.63733608273537,
latitude: 62.5222391678993,
name: "Ålesund"
},  

{
code: "municip5546",
longitude: 22.0855019479781,
latitude: 69.7913629217364,
name: "Kvænangen"
},  

{
code: "municip5536",
longitude: 20.1064058864134,
latitude: 69.6799551280096,
name: "Lyngen"
},  

{
code: "municip5534",
longitude: 19.2602665005253,
latitude: 69.9667823156377,
name: "Karlsøy"
},  

{
code: "municip5540",
longitude: 20.7768520392465,
latitude: 69.4616233812877,
name: "Gáivuotna-Kåfjord-Kaivuono"
},  

{
code: "municip5538",
longitude: 20.3746863138919,
latitude: 69.2238318358534,
name: "Storfjord-Omasvuotna-Omasvuono"
},  

{
code: "municip1506",
longitude: 7.99386598074772,
latitude: 62.6333706900731,
name: "Molde"
},  

{
code: "municip5634",
longitude: 30.6342528799352,
latitude: 70.3304229971209,
name: "Vardø"
},  

{
code: "municip1505",
longitude: 7.79681188036635,
latitude: 63.0642664151196,
name: "Kristiansund"
},  

{
code: "municip5601",
longitude: 23.275849393773,
latitude: 69.9440297592027,
name: "Alta"
},  

{
code: "municip5603",
longitude: 24.2384358833306,
latitude: 70.4044663563826,
name: "Hammerfest"
},  

{
code: "municip5607",
longitude: 29.6804656624947,
latitude: 70.2310128793224,
name: "Vadsø"
},  

{
code: "municip3452",
longitude: 8.88290989114675,
latitude: 61.0346193689698,
name: "Vestre Slidre"
},  

{
code: "municip5503",
longitude: 16.4332419620245,
latitude: 68.7339645255406,
name: "Harstad"
},  

{
code: "municip3451",
longitude: 9.28374234563023,
latitude: 60.967843436909,
name: "Nord-Aurdal"
},  

{
code: "municip5501",
longitude: 19.4609762287946,
latitude: 69.5669807214215,
name: "Tromsø"
},  

{
code: "municip3454",
longitude: 8.47747109051767,
latitude: 61.2176474329495,
name: "Vang"
},  

{
code: "municip3453",
longitude: 9.09864037605058,
latitude: 61.2448383975936,
name: "Øystre Slidre"
},  

{
code: "municip5512",
longitude: 16.7813139601981,
latitude: 68.5731573009315,
name: "Tjeldsund"
},  

{
code: "municip5510",
longitude: 16.014035486213,
latitude: 68.6580290745421,
name: "Kvæfjord"
},  

{
code: "municip3448",
longitude: 9.96914426394284,
latitude: 60.9460850686308,
name: "Nordre Land"
},  

{
code: "municip5516",
longitude: 17.5224502787792,
latitude: 68.6875959900536,
name: "Gratangen"
},  

{
code: "municip3447",
longitude: 10.2713673194608,
latitude: 60.6763758667061,
name: "Søndre Land"
},  

{
code: "municip5514",
longitude: 17.2790234416981,
latitude: 68.8741934680536,
name: "Ibestad"
},  

{
code: "municip3450",
longitude: 9.63855938258889,
latitude: 60.9743731868625,
name: "Etnedal"
},  

{
code: "municip3449",
longitude: 9.63417620021094,
latitude: 60.6789292048445,
name: "Sør-Aurdal"
},  

{
code: "municip3436",
longitude: 9.52280404646802,
latitude: 61.5840431690934,
name: "Nord-Fron"
},  

{
code: "municip3435",
longitude: 8.95345969228018,
latitude: 61.6725601059295,
name: "Vågå"
},  

{
code: "municip3438",
longitude: 9.78708429356047,
latitude: 61.5369839004312,
name: "Sør-Fron"
},  

{
code: "municip3437",
longitude: 9.46760904559107,
latitude: 61.7726838975542,
name: "Sel"
},  

{
code: "municip3432",
longitude: 8.63933319069675,
latitude: 62.1918905910321,
name: "Lesja"
},  

{
code: "municip3431",
longitude: 9.46225425630851,
latitude: 62.0755464257915,
name: "Dovre"
},  

{
code: "municip3434",
longitude: 8.48373247543763,
latitude: 61.715789635344,
name: "Lom"
},  

{
code: "municip3433",
longitude: 7.88276919427233,
latitude: 61.934820498289,
name: "Skjåk"
},  

{
code: "municip3443",
longitude: 10.6198491348588,
latitude: 60.6433709791867,
name: "Vestre Toten"
},  

{
code: "municip3446",
longitude: 10.5121253284522,
latitude: 60.4311281625621,
name: "Gran"
},  

{
code: "municip3440",
longitude: 10.5338428177303,
latitude: 61.3202183984889,
name: "Øyer"
},  

{
code: "municip3439",
longitude: 10.3183455659011,
latitude: 61.5595461513661,
name: "Ringebu"
},  

{
code: "municip1579",
longitude: 7.23037230382361,
latitude: 62.8865896382717,
name: "Hustadvika"
},  

{
code: "municip3442",
longitude: 10.893103281205,
latitude: 60.6109783524427,
name: "Østre Toten"
},  

{
code: "municip3441",
longitude: 9.85751803825281,
latitude: 61.2717446399936,
name: "Gausdal"
},  

{
code: "municip3420",
longitude: 11.7355255760469,
latitude: 60.9571950135218,
name: "Elverum"
},  

{
code: "municip1576",
longitude: 8.71683979439244,
latitude: 63.2916020063324,
name: "Aure"
},  

{
code: "municip3419",
longitude: 11.954090002207,
latitude: 60.786408294059,
name: "Våler (Innlandet)"
},
  
{
code: "municip3422",
longitude: 11.4971369634782,
latitude: 61.2554249691882,
name: "Åmot"
},  

{
code: "municip1578",
longitude: 7.38804740395319,
latitude: 62.288279852554,
name: "Fjord"
},  

{
code: "municip3421",
longitude: 12.2862939788603,
latitude: 61.3270756756178,
name: "Trysil"
},  

{
code: "municip1577",
longitude: 6.27134087264407,
latitude: 62.0536072152372,
name: "Volda"
},  

{
code: "municip3416",
longitude: 12.0655163352988,
latitude: 59.9966510491602,
name: "Eidskog"
},  

{
code: "municip3415",
longitude: 11.7549610314779,
latitude: 60.2220434393062,
name: "Sør-Odal"
},  

{
code: "municip3418",
longitude: 12.1613871202639,
latitude: 60.6498435121307,
name: "Åsnes"
},  

{
code: "municip3417",
longitude: 12.220414359919,
latitude: 60.4423400268804,
name: "Grue"
},  

{
code: "municip1868",
longitude: 15.0954796654529,
latitude: 68.8686867715941,
name: "Øksnes"
},  

{
code: "municip3428",
longitude: 10.5128808517758,
latitude: 62.068044929745,
name: "Alvdal"
},  

{
code: "municip1867",
longitude: 14.6134080457642,
latitude: 68.7106720720087,
name: "Bø"
},  

{
code: "municip3427",
longitude: 10.5355077587881,
latitude: 62.4013475565667,
name: "Tynset"
},  

{
code: "municip1870",
longitude: 15.6607400261337,
latitude: 68.7166339854996,
name: "Sortland-Suortá"
},  

{
code: "municip3430",
longitude: 11.3145179190133,
latitude: 62.4435371368999,
name: "Os"
},  

{
code: "municip3429",
longitude: 10.0196713404932,
latitude: 62.1738825992287,
name: "Folldal"
},  

{
code: "municip3424",
longitude: 11.2785229060949,
latitude: 61.8188788402679,
name: "Rendalen"
},  

{
code: "municip1563",
longitude: 8.71660488776465,
latitude: 62.5851017112518,
name: "Sunndal"
},  

{
code: "municip3423",
longitude: 10.7817235923202,
latitude: 61.5895379328648,
name: "Stor-Elvdal"
},  

{
code: "municip1866",
longitude: 14.8678453855741,
latitude: 68.4013222734525,
name: "Hadsel"
},  

{
code: "municip1865",
longitude: 14.5113590290723,
latitude: 68.2897170959439,
name: "Vågan"
},  

{
code: "municip1566",
longitude: 8.86212421138232,
latitude: 62.9201592784497,
name: "Surnadal"
},  

{
code: "municip3426",
longitude: 11.0742412660623,
latitude: 62.3564720166376,
name: "Tolga"
},  

{
code: "municip3425",
longitude: 11.9507783916268,
latitude: 61.980608563377,
name: "Engerdal"
},  

{
code: "municip1875",
longitude: 16.1954627664424,
latitude: 67.9021201655753,
name: "Hamarøy"
},  

{
code: "municip1560",
longitude: 8.18072397484492,
latitude: 62.9486815913562,
name: "Tingvoll"
},  

{
code: "municip3403",
longitude: 11.1409380520915,
latitude: 60.9615695107951,
name: "Hamar"
},  

{
code: "municip3405",
longitude: 10.3876833543182,
latitude: 61.1284761629564,
name: "Lillehammer"
},  

{
code: "municip1871",
longitude: 15.7921568072612,
latitude: 69.1091380791965,
name: "Andøy"
},  

{
code: "municip1874",
longitude: 12.9764183667208,
latitude: 67.9276199693162,
name: "Moskenes"
},  

{
code: "municip3401",
longitude: 12.2201718843563,
latitude: 60.2183310641925,
name: "Kongsvinger"
},  

{
code: "municip1573",
longitude: 8.01156624113742,
latitude: 63.3948242435957,
name: "Smøla"
},  

{
code: "municip3412",
longitude: 11.3867916812456,
latitude: 60.848969284853,
name: "Løten"
},  

{
code: "municip3411",
longitude: 10.8502109726015,
latitude: 60.9961355956737,
name: "Ringsaker"
},  

{
code: "municip3414",
longitude: 11.5809608492435,
latitude: 60.437407433251,
name: "Nord-Odal"
},  

{
code: "municip3413",
longitude: 11.3320768072399,
latitude: 60.644200425161,
name: "Stange"
},  

{
code: "municip3407",
longitude: 10.4720451578527,
latitude: 60.886976912436,
name: "Gjøvik"
},  

{
code: "municip1547",
longitude: 6.87108533519798,
latitude: 62.8202471389041,
name: "Aukra"
},  

{
code: "municip1557",
longitude: 7.82540856364272,
latitude: 62.8903284295324,
name: "Gjemnes"
},  

{
code: "municip3107",
longitude: 10.9337988112452,
latitude: 59.2311728622681,
name: "Fredrikstad"
},  

{
code: "municip1554",
longitude: 7.55563848112206,
latitude: 63.0135211049338,
name: "Averøy"
},  

{
code: "municip3105",
longitude: 11.1590641129026,
latitude: 59.2894431062271,
name: "Sarpsborg"
},  

{
code: "municip3303",
longitude: 9.6674364361885,
latitude: 59.5788307873624,
name: "Kongsberg"
},  

{
code: "municip3301",
longitude: 10.1408903911257,
latitude: 59.716063169146,
name: "Drammen"
},  

{
code: "municip1149",
longitude: 5.24771557188843,
latitude: 59.2568478039685,
name: "Karmøy"
},  

{
code: "municip3103",
longitude: 10.7230099212669,
latitude: 59.4017673932776,
name: "Moss"
},  

{
code: "municip1144",
longitude: 5.41247846473029,
latitude: 59.0670501597273,
name: "Kvitsøy"
},  

{
code: "municip3101",
longitude: 11.5016994308049,
latitude: 59.0864201623047,
name: "Halden"
},  

{
code: "municip3124",
longitude: 11.6988926089256,
latitude: 59.2143974075664,
name: "Aremark"
},  

{
code: "municip1146",
longitude: 5.49404338818405,
latitude: 59.3828416072113,
name: "Tysvær"
},  

{
code: "municip3110",
longitude: 11.0244397597946,
latitude: 59.0557158777249,
name: "Hvaler"
},  

{
code: "municip1145",
longitude: 5.42048922822861,
latitude: 59.2016307737205,
name: "Bokn"
},  

{
code: "municip3118",
longitude: 11.2180958495142,
latitude: 59.5948611132379,
name: "Indre Østfold"
},  

{
code: "municip3122",
longitude: 11.6481852368316,
latitude: 59.4909196413228,
name: "Marker"
},  

{
code: "municip3305",
longitude: 10.0898350892277,
latitude: 60.2936805906213,
name: "Ringerike"
},  

{
code: "municip1151",
longitude: 4.88604925298334,
latitude: 59.3051279822136,
name: "Utsira"
},  

{
code: "municip1134",
longitude: 6.60429659349687,
latitude: 59.517109281693,
name: "Suldal"
},  

{
code: "municip1133",
longitude: 6.46747161850585,
latitude: 59.2218542029483,
name: "Hjelmeland"
},  

{
code: "municip1127",
longitude: 5.61223276194218,
latitude: 58.9997266703127,
name: "Randaberg"
},  

{
code: "municip1130",
longitude: 6.06995681877984,
latitude: 59.0372456134272,
name: "Strand"
},  

{
code: "municip1135",
longitude: 6.4532123623084,
latitude: 59.7050698854475,
name: "Sauda"
},  

{
code: "municip5052",
longitude: 11.6215880679431,
latitude: 65.0834297534939,
name: "Leka"
},  

{
code: "municip5054",
longitude: 10.2811223980137,
latitude: 63.6806293618347,
name: "Indre Fosen"
},  

{
code: "municip1112",
longitude: 6.47461999615026,
latitude: 58.491547461301,
name: "Lund"
},  

{
code: "municip5053",
longitude: 10.8761719243206,
latitude: 63.8172594890615,
name: "Inderøy"
},  

{
code: "municip1111",
longitude: 6.3348016691278,
latitude: 58.3767269205461,
name: "Sokndal"
},  

{
code: "municip1114",
longitude: 6.19659501679567,
latitude: 58.6623999706218,
name: "Bjerkreim"
},  

{
code: "municip5047",
longitude: 11.9009579715713,
latitude: 64.456024900245,
name: "Overhalla"
},  

{
code: "municip1124",
longitude: 5.61821408035957,
latitude: 58.882630160784,
name: "Sola"
},  

{
code: "municip5049",
longitude: 10.8776573703812,
latitude: 64.421248612262,
name: "Flatanger"
},  

{
code: "municip5060",
longitude: 11.8263955367464,
latitude: 64.9342408491244,
name: "Nærøysund"
},  

{
code: "municip5059",
longitude: 9.64852664386216,
latitude: 63.2642883871277,
name: "Orkland"
},  

{
code: "municip1120",
longitude: 5.5997430135414,
latitude: 58.7656626812183,
name: "Klepp"
},  

{
code: "municip5061",
longitude: 9.29364035144385,
latitude: 63.0326492455543,
name: "Rindal"
},  

{
code: "municip5056",
longitude: 8.71935725572189,
latitude: 63.534699759504,
name: "Hitra"
},  

{
code: "municip1119",
longitude: 5.73608211194222,
latitude: 58.5988275155901,
name: "Hå"
},  

{
code: "municip1122",
longitude: 6.24885811862993,
latitude: 58.81038992681,
name: "Gjesdal"
},  

{
code: "municip5055",
longitude: 8.88971454659712,
latitude: 63.2268534106314,
name: "Heim"
},  

{
code: "municip1121",
longitude: 5.77162425829529,
latitude: 58.7062734957493,
name: "Time"
},  

{
code: "municip5058",
longitude: 10.4122093339001,
latitude: 64.0293861227836,
name: "Åfjord"
},  

{
code: "municip5057",
longitude: 9.86676616589187,
latitude: 63.7925162329614,
name: "Ørland"
},  

{
code: "municip5036",
longitude: 10.7910495269056,
latitude: 63.6052724455726,
name: "Frosta"
},  

{
code: "municip5035",
longitude: 11.2081228002739,
latitude: 63.4639174775011,
name: "Stjørdal"
},  

{
code: "municip1101",
longitude: 6.1541802277135,
latitude: 58.5064976026795,
name: "Eigersund"
},  

{
code: "municip5038",
longitude: 12.0138288696533,
latitude: 63.7847797484424,
name: "Verdal"
},  

{
code: "municip5037",
longitude: 11.2765707101916,
latitude: 63.6501892376086,
name: "Levanger"
},  

{
code: "municip5032",
longitude: 11.1334597048145,
latitude: 63.200741891297,
name: "Selbu"
},  

{
code: "municip5031",
longitude: 10.7492073793825,
latitude: 63.373518964639,
name: "Malvik"
},  

{
code: "municip5034",
longitude: 11.8278532197352,
latitude: 63.4009473212519,
name: "Meråker"
},  

{
code: "municip5033",
longitude: 11.8089725387387,
latitude: 63.0062266913366,
name: "Tydal"
},  

{
code: "municip1108",
longitude: 6.50512744620235,
latitude: 59.0271722413659,
name: "Sandnes"
},  

{
code: "municip5044",
longitude: 13.0054919543386,
latitude: 64.8628531326406,
name: "Namsskogan"
},  

{
code: "municip5043",
longitude: 13.6430621773096,
latitude: 64.9028072293227,
name: "Raarvihke-Røyrvik"
},  

{
code: "municip5046",
longitude: 12.3601439886942,
latitude: 64.7444570917345,
name: "Høylandet"
},  

{
code: "municip5045",
longitude: 12.5870779657394,
latitude: 64.5135975563769,
name: "Grong"
},  

{
code: "municip1103",
longitude: 5.69252983765359,
latitude: 58.945808815807,
name: "Stavanger"
},  

{
code: "municip1106",
longitude: 5.30309341735558,
latitude: 59.4469062736477,
name: "Haugesund"
},  

{
code: "municip5042",
longitude: 13.6290518212875,
latitude: 64.3662529252964,
name: "Lierne"
},  

{
code: "municip5041",
longitude: 12.6297672504942,
latitude: 64.2051205757401,
name: "Snåase-Snåsa"
},  

{
code: "municip1580",
longitude: 6.52254212910888,
latitude: 62.5741071043019,
name: "Haram"
},  
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code.toLowerCase())
);

// Source: https://fr.wikipedia.org/wiki/ISO_3166
const frenchCountryNames: Record<string, string> = {
  municip0301: "A",
  municip5020: "A",
  municip5022: "A",
  municip5021: "A",
  municip5028: "A",
  municip5027: "A",
  municip5029: "A",
  municip4602: "A",
  municip4601: "A",
  municip3052: "A",
  municip4612: "A",
  municip3051: "A",
  municip4611: "A",
  municip3054: "A",
  municip5026: "A",
  municip5025: "A",
  municip4614: "A",
  municip3053: "A",
  municip4613: "A",
  municip3048: "A",
  municip3047: "A",
  municip3050: "A",
  municip5006: "A",
  municip3049: "A",
  municip5001: "A",
  municip5014: "A",
  municip3036: "A",
  municip3035: "A",
  municip5007: "A",
  municip3038: "A",
  municip3037: "A",
  municip3032: "A",
  municip3031: "A",
  municip3034: "A",
  municip3033: "A",
  municip3044: "A",
  municip3043: "A",
  municip3046: "A",
  municip3045: "A",
  municip3040: "A",
  municip3039: "A",
  municip3042: "A",
  municip3041: "A",
  municip3020: "A",
  municip3019: "A",
  municip3022: "A",
  municip3021: "A",
  municip3016: "A",
  municip3015: "A",
  municip3018: "A",
  municip1160: "A",
  municip3017: "A",
  municip3028: "A",
  municip3027: "A",
  municip3030: "A",
  municip3029: "A",
  municip3024: "A",
  municip3023: "A",
  municip3026: "A",
  municip3025: "A",
  municip4651: "A",
  municip4648: "A",
  municip4647: "A",
  municip4650: "A",
  municip4649: "A",
  municip4636: "A",
  municip4635: "A",
  municip4638: "A",
  municip4637: "A",
  municip4632: "A",
  municip4631: "A",
  municip4634: "A",
  municip4633: "A",
  municip4644: "A",
  municip4643: "A",
  municip4646: "A",
  municip4645: "A",
  municip4640: "A",
  municip4639: "A",
  municip4642: "A",
  municip4641: "A",
  municip4620: "A",
  municip4619: "A",
  municip4622: "A",
  municip4621: "A",
  municip4616: "A",
  municip4615: "A",
  municip4618: "A",
  municip4617: "A",
  municip4628: "A",
  municip4627: "A",
  municip4630: "A",
  municip4629: "A",
  municip4624: "A",
  municip4623: "A",
  municip4626: "A",
  municip4625: "A",
  municip4220: "A",
  municip4219: "A",
  municip4222: "A",
  municip4221: "A",
  municip4216: "A",
  municip4215: "A",
  municip4218: "A",
  municip4217: "A",
  municip4228: "A",
  municip4227: "A",
  municip4224: "A",
  municip4223: "A",
  municip4226: "A",
  municip4225: "A",
  municip4204: "A",
  municip4203: "A",
  municip4206: "A",
  municip4205: "A",
  municip4202: "A",
  municip4201: "A",
  municip4212: "A",
  municip4211: "A",
  municip4214: "A",
  municip4213: "A",
  municip4207: "A",
  municip3820: "A",
  municip3819: "A",
  municip3822: "A",
  municip3821: "A",
  municip3816: "A",
  municip3815: "A",
  municip3818: "A",
  municip3817: "A",
  municip3824: "A",
  municip3823: "A",
  municip1851: "A",
  municip3825: "A",
  municip1853: "A",
  municip3804: "A",
  municip1848: "A",
  municip3803: "A",
  municip3806: "A",
  municip3805: "A",
  municip1860: "A",
  municip1859: "A",
  municip3802: "A",
  municip3801: "A",
  municip3812: "A",
  municip1856: "A",
  municip3811: "A",
  municip3814: "A",
  municip3813: "A",
  municip1857: "A",
  municip1836: "A",
  municip3808: "A",
  municip1835: "A",
  municip3807: "A",
  municip1838: "A",
  municip1837: "A",
  municip1832: "A",
  municip1834: "A",
  municip1833: "A",
  municip1845: "A",
  municip1840: "A",
  municip1839: "A",
  municip1532: "A",
  municip1841: "A",
  municip1531: "A",
  municip1820: "A",
  municip1822: "A",
  municip1528: "A",
  municip1816: "A",
  municip1815: "A",
  municip1818: "A",
  municip1539: "A",
  municip1828: "A",
  municip1827: "A",
  municip1535: "A",
  municip1824: "A",
  municip5436: "A",
  municip1516: "A",
  municip5435: "A",
  municip1826: "A",
  municip1825: "A",
  municip1515: "A",
  municip5438: "A",
  municip1804: "A",
  municip5437: "A",
  municip1517: "A",
  municip5432: "A",
  municip1806: "A",
  municip1511: "A",
  municip5434: "A",
  municip5433: "A",
  municip1514: "A",
  municip5444: "A",
  municip5443: "A",
  municip1812: "A",
  municip5440: "A",
  municip1811: "A",
  municip1525: "A",
  municip5439: "A",
  municip1520: "A",
  municip5442: "A",
  municip1813: "A",
  municip5441: "A",
  municip5420: "A",
  municip5419: "A",
  municip5422: "A",
  municip5421: "A",
  municip5416: "A",
  municip5415: "A",
  municip5418: "A",
  municip5417: "A",
  municip5428: "A",
  municip5427: "A",
  municip5430: "A",
  municip1507: "A",
  municip5429: "A",
  municip5424: "A",
  municip5423: "A",
  municip5426: "A",
  municip5425: "A",
  municip1506: "A",
  municip5404: "A",
  municip1505: "A",
  municip5403: "A",
  municip5406: "A",
  municip5405: "A",
  municip3452: "A",
  municip5402: "A",
  municip3451: "A",
  municip5401: "A",
  municip3454: "A",
  municip3453: "A",
  municip5412: "A",
  municip5411: "A",
  municip3448: "A",
  municip5414: "A",
  municip3447: "A",
  municip5413: "A",
  municip3450: "A",
  municip3449: "A",
  municip3436: "A",
  municip3435: "A",
  municip3438: "A",
  municip3437: "A",
  municip3432: "A",
  municip3431: "A",
  municip3434: "A",
  municip3433: "A",
  municip3443: "A",
  municip3446: "A",
  municip3440: "A",
  municip3439: "A",
  municip1579: "A",
  municip3442: "A",
  municip3441: "A",
  municip3420: "A",
  municip1576: "A",
  municip3419: "A",
  municip3422: "A",
  municip1578: "A",
  municip3421: "A",
  municip1577: "A",
  municip3416: "A",
  municip3415: "A",
  municip3418: "A",
  municip3417: "A",
  municip1868: "A",
  municip3428: "A",
  municip1867: "A",
  municip3427: "A",
  municip1870: "A",
  municip3430: "A",
  municip3429: "A",
  municip3424: "A",
  municip1563: "A",
  municip3423: "A",
  municip1866: "A",
  municip1865: "A",
  municip1566: "A",
  municip3426: "A",
  municip3425: "A",
  municip1875: "A",
  municip1560: "A",
  municip3403: "A",
  municip3405: "A",
  municip1871: "A",
  municip1874: "A",
  municip3401: "A",
  municip1573: "A",
  municip3412: "A",
  municip3411: "A",
  municip3414: "A",
  municip3413: "A",
  municip3407: "A",
  municip1547: "A",
  municip1557: "A",
  municip3004: "A",
  municip1554: "A",
  municip3003: "A",
  municip3006: "A",
  municip3005: "A",
  municip1149: "A",
  municip3002: "A",
  municip1144: "A",
  municip3001: "A",
  municip3012: "A",
  municip1146: "A",
  municip3011: "A",
  municip1145: "A",
  municip3014: "A",
  municip3013: "A",
  municip3007: "A",
  municip1151: "A",
  municip1134: "A",
  municip1133: "A",
  municip1127: "A",
  municip1130: "A",
  municip1135: "A",
  municip5052: "A",
  municip5054: "A",
  municip1112: "A",
  municip5053: "A",
  municip1111: "A",
  municip1114: "A",
  municip5047: "A",
  municip1124: "A",
  municip5049: "A",
  municip5060: "A",
  municip5059: "A",
  municip1120: "A",
  municip5061: "A",
  municip5056: "A",
  municip1119: "A",
  municip1122: "A",
  municip5055: "A",
  municip1121: "A",
  municip5058: "A",
  municip5057: "A",
  municip5036: "A",
  municip5035: "A",
  municip1101: "A",
  municip5038: "A",
  municip5037: "A",
  municip5032: "A",
  municip5031: "A",
  municip5034: "A",
  municip5033: "A",
  municip1108: "A",
  municip5044: "A",
  municip5043: "A",
  municip5046: "A",
  municip5045: "A",
  municip1103: "A",
  municip1106: "A",
  municip5042: "A",
  municip5041: "A",
};

export function getCountryName(language: string, country: Country) {
  if (language === "fr") {
    return frenchCountryNames[country.code];
  }
  return country.name;
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
